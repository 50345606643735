import {
    FieldNumberWithControlsContainer as SourceFieldNumberWithControlsContainer,
} from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.container';

import FieldNumberWithControls from './FieldNumberWithControls.component';

/** @namespace Pwa/Component/FieldNumberWithControls/Container */
export class FieldNumberWithControlsContainer extends SourceFieldNumberWithControlsContainer {
    containerProps() {
        const {
            attr: {
                autoComplete,
                defaultValue,
                ...attr
            } = {},
            value,
            events,
            isDisabled,
        } = this.props;

        const { value: stateValue } = this.state;

        const { fieldRef } = this;

        return {
            attr: {
                ...attr,
                autoComplete,
            },
            value,
            events,
            isDisabled,
            stateValue,
            fieldRef,
        };
    }
}

export default FieldNumberWithControls;
