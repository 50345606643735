import {
    FieldComponent as SourceFieldComponent,
} from 'SourceComponent/Field/Field.component';
import { ReactElement } from 'Type/Common.type';

import './Field.override.style';

/** @namespace Pwa/Component/Field/Component */
export class FieldComponent extends SourceFieldComponent {
    renderErrorMessage(message: string, key: string): ReactElement {
        // eslint-disable-next-line react/no-danger, @typescript-eslint/naming-convention
        return <div block="Field" elem="ErrorMessage" key={ key } dangerouslySetInnerHTML={ { __html: message } } />;
    }
}

export default FieldComponent;
