/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
// @ts-nocheck
import AddIcon from 'Component/AddIcon';
import { FieldType } from 'Component/Field/Field.config';
import MinusIcon from 'Component/MinusIcon';
import {
    FieldNumberWithControlsComponent as SourceFieldNumberWithControlsComponent,
} from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.component';
import { ReactElement } from 'Type/Common.type';
import { DEFAULT_MAX_PRODUCTS } from 'Util/Product/Product.type';

import './FieldNumberWithControls.override.style';

/** @namespace Pwa/Component/FieldNumberWithControls/Component */
export class FieldNumberWithControlsComponent extends SourceFieldNumberWithControlsComponent {
    __construct(props) {
        super.__construct(props);
        this.state = {
            stateValue: props.value || 1,
        };
    }

    componentDidUpdate(prevProps) {
        const { value, fieldRef } = this.props;

        if (prevProps.value !== value) {
            fieldRef.value = value;
            this.setState({ stateValue: value });
        }
    }

    onChangeCartQty = (e) => {
        const {
            handleValueChange,
            attr: { min = 1, max = DEFAULT_MAX_PRODUCTS },
        } = this.props;

        const { fieldRef } = this.props;
        const { stateValue } = this.state;

        const { value: newValue } = e.target;
        const parsedValue = parseFloat(newValue);

        if (isNaN(parsedValue)) {
            fieldRef.value = stateValue;
        } else if (parsedValue < min) {
            this.setState({ stateValue: min });
            handleValueChange(min);
        } else if (parsedValue > max) {
            this.setState({ stateValue: max });
            handleValueChange(max);
        } else {
            this.setState({ stateValue: parsedValue });
            handleValueChange(parsedValue);
        }
    };

    render(): ReactElement {
        const {
            attr,
            attr: { min = 1, max = DEFAULT_MAX_PRODUCTS },
            value,
            setRef,
            stateValue,
            handleValueChange,
            isDisabled,
        } = this.props;

        const numberValue = +value || +stateValue;

        return (
            <>
                <input
                  ref={ (elem) => setRef(elem) }
                  { ...attr }
                  type={ FieldType.NUMBER }
                  onBlur={ this.onChangeCartQty }
                  onKeyDown={ (e) => {
                      if (e.key === 'Enter') {
                          e.preventDefault();
                          (e.target as HTMLInputElement).blur();
                      }
                  } }
                  aria-label={ __('Value') }
                  disabled={ isDisabled }
                />
                <button
                  disabled={ max === 1 || numberValue >= max || isDisabled }
                  onClick={ () => handleValueChange(numberValue + (attr.minIncrement ?? 1)) }
                  aria-label={ __('Add') }
                  type={ FieldType.BUTTON }
                >
                    <AddIcon block="SubtractButton" isPrimary />
                </button>
                <button
                  disabled={ numberValue + 1 === min || numberValue <= min || isDisabled }
                  onClick={ () => handleValueChange(numberValue - (attr.minIncrement ?? 1)) }
                  aria-label={ __('Subtract') }
                  type={ FieldType.BUTTON }
                >
                    <MinusIcon block="AddButton" isPrimary />
                </button>
            </>
        );
    }
}

export default FieldNumberWithControlsComponent;
